import React from "react";
import Loading from "../images/loading.gif";

const Spinner = () => {
    return (
        <>
            <div className="text-center">
                <img className="my-5" src={Loading} style={{ height: "250px", width: "250px" }} alt="loading-response" />
                <h2 className="text-center mt-5">Loading, Please wait a moment</h2>
            </div>
        </>
    )
}

export default Spinner;